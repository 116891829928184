<template>
    <div class="home">
        <div class="level">
            <div class="level-left">
                <div class="level-item">
                    <h1 class="title is-4">{{ $t("Interface.Log.Title") }}</h1>
                </div>
            </div>
            <!-- <div class="level-right">
                <div class="level-item">
                </div>
            </div> -->
        </div>
        <b-table
            :data="Logs"
            :striped="true"
            :mobile-cards="true"
            :paginated="true"
            :hoverable="true"
            :per-page="ItemsPerPage"
            :current-page.sync="PageNumber"
            :total="LogsAmount"
            :loading="IsLoading"
            default-sort="id"
            icon-pack="fa"
            detailed
            detail-key="id"
        >
            <b-table-column v-slot="props" field="date" :label="$t('Interface.Log.Date')" sortable>
                {{ filterDateTime(props.row.date) }}
            </b-table-column>
            <b-table-column v-slot="props" field="userName" :label="$t('Interface.Log.UserName')" sortable>
                {{ trimLongString(props.row.userName, 16) }}
            </b-table-column>
            <b-table-column v-slot="props" field="doorName" :label="$t('Interface.Log.DoorName')" sortable>
                {{ trimLongString(props.row.doorName, 16) }}
            </b-table-column>
            <b-table-column v-slot="props" field="keyDescription" :label="$t('Interface.Log.KeyName')" sortable>
                {{ trimLongString(props.row.keyDescription, 16) }}
            </b-table-column>
            <b-table-column v-slot="props" field="status" :label="$t('Interface.Log.Status.Title')" sortable>
                <span v-if="props.row.status === 0" class="has-text-success">
                    {{ $t("Interface.Log.Status.Open") }}
                </span>
                <span v-else class="has-text-danger">
                    {{ $t("Interface.Log.Status.NotOpen") }}
                </span>
            </b-table-column>

            <template slot="detail" slot-scope="props">
                <article class="media">
                    <div class="media-content">
                        <div class="content">
                            <p>
                                <strong>
                                    <span v-if="props.row.status === 0" class="has-text-success">
                                        {{ $t("Interface.Log.Status.Open") }}
                                    </span>
                                    <span v-else class="has-text-danger">
                                        {{ $t("Interface.Log.Status.NotOpen") }}
                                    </span>
                                </strong>
                                <br>
                                <br>
                                <strong>{{ props.row.userName }} ({{ props.row.phone }})</strong>
                                <br>
                                <small>{{ filterDateTime(props.row.date) }}</small>
                                <br>
                                <font-awesome-icon :icon="['fas', 'door-open']" class="has-text-grey" /> {{ props.row.doorName }}
                                <br>
                                <font-awesome-icon :icon="['fas', 'key']" class="has-text-grey" /> {{ props.row.keyDescription }}
                                <template v-if="props.row.description && props.row.description.length > 0">
                                    <br>
                                    <br>
                                    {{ $t("Interface.Log.Result") }}:
                                    <br>
                                    {{ props.row.description }}
                                </template>
                            </p>
                        </div>
                    </div>
                </article>
            </template>

            <template slot="empty">
                <section class="section">
                    <div class="content has-text-grey has-text-centered">
                        <p>{{ $t("Interface.Door.List.Empty") }}</p>
                    </div>
                </section>
            </template>
        </b-table>
    </div>
</template>

<script>
import Helpers from "@/utils/Helpers"
import LoadingState from "@/mixins/LoadingState"
import {
    LOG_LIST_REQUEST
} from "@/store/actions/log"

export default {
    name: "Logs",
    mixins: [LoadingState],
    data() {
        return {
            PageNumber: 1,
            ItemsPerPage: 20
        }
    },
    computed: {
        LogsAmount() {
            return this.$store.getters.LOGS_AMOUNT
        },
        Logs() {
            return this.$store.state.Log.List
        }
    },
    mounted() {
        this.loadLogs()
    },
    methods: {
        loadLogs() {
            this.switchLoading()
            this.$store
                .dispatch(LOG_LIST_REQUEST)
                .finally(() => this.switchLoading())
        },
        filterDateTime(date) {
            return Helpers.filterDateTime(date)
        },
        trimLongString(text, symbols) {
            if (text && text.length > symbols + 2) return `${text.substring(0, symbols)}...`
            return text
        }
    },
    metaInfo() {
        return {
            title: this.$t("Interface.Log.Title")
        }
    }
}
</script>
